.interest_top {
  display: flex;
  flex-direction: column;
  gap: 8px;

  .interest_top_list {
    display: flex;
    flex-direction: row;
    gap: 8px;

    .item_top_user {
      width: 90px;
      display: flex;
      flex-direction: column;
      gap: 4px;

      align-items: center;
      justify-content: flex-start;

      .item_top_user_name {
        width: 100%;
        text-align: center;
        text-overflow: ellipsis;
        overflow: hidden;
        // Addition lines for 2 line or multiline ellipsis
        display: -webkit-box !important;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        white-space: normal;
      }
    }
  }
}
