.pink_bg_btn {
  width: 100%;
  position: relative;

  .btn_content {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    display: flex;
    align-items: center;
    justify-content: center;
  }
}
