@import "shared/utils/colors.scss";

.opacity-modal {
  display: flex;

  position: absolute;
  right: 0px;
  top: 94px;
  width: 100vw;
  height: calc(100vh - 94px);
  background: rgba(0, 0, 0, 0.3);
  backdrop-filter: blur(10px);
  text-align: center;

  z-index: 100000;

  .opacity-modal-content {
    flex: 1;
  }
}
