.modal_confirm_download_app {
  width: 100%;
  height: 100%;

  display: flex;
  align-items: center;
  justify-content: center;

  .modal_body {
    min-height: 200px;
    border-radius: 32px;
    background-color: white;

    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 24px;
    gap: 32px;

    .close-btn {
      width: 40px;
      height: 40px;
      border: 1px solid #f3f7fa;
      border-radius: 40px;

      display: flex;
      align-items: center;
      justify-content: center;
    }

    .content_view {
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .btn-confirm {
      width: 150px;
      height: 48px;
      border-radius: 8px;
      background-color: #ea21a2;

      display: flex;
      align-items: center;
      justify-content: center;

      .btn-txt {
        color: white;
      }
    }

    .btn-cancel {
      width: 150px;
      height: 48px;
      border-radius: 8px;
      border: 1px solid #ea21a2;

      display: flex;
      align-items: center;
      justify-content: center;

      .btn-txt {
        color: black;
      }
    }
  }
}
