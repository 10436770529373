.login-social {
  margin-top: 32px;
  gap: 8px;

  .social-icon {
    width: 70px;
    height: 70px;
    border-radius: 70px;
    border: 1px #c7c9ca solid;

    display: flex;
    align-items: center;
    justify-content: center;
  }
}
