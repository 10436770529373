.modal_creating_mood {
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .modal_content {
    // width: 300px;
    // height: 500px;
    // background-color: white;
    // border-radius: 32px;

    // display: flex;
    // flex-direction: column;
    // align-items: center;
    // justify-content: center;
  }
}
