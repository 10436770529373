.modal_reward_detail {
  width: 100%;
  height: 100%;

  display: flex;
  align-items: center;
  justify-content: center;

  .modal_body {
    width: 400px;
    min-height: 350px;
    border-radius: 32px;
    background-color: white;

    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 24px;
    gap: 32px;

    .close-btn {
      width: 40px;
      height: 40px;
      border: 1px solid #f3f7fa;
      border-radius: 40px;

      display: flex;
      align-items: center;
      justify-content: center;
    }

    .content_view {
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .btn-thank {
      width: 170px;
      height: 48px;
      border-radius: 8px;
      background-color: rgba($color: #ea21a2, $alpha: 0.2);

      display: flex;
      align-items: center;
      justify-content: center;

      .btn-txt {
        color: #ea21a2;
      }
    }
  }
}
