.explore_topic {
  display: flex;
  flex-direction: column;
  flex: 1;

  min-height: 100%;
  margin-top: 32px;
  margin-bottom: 0px;
  padding-right: 22px;

  .topic_title {
    align-self: flex-start;
  }

  .topic_list {
    width: calc(100% - 22px);
  }

  .empty_container {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    gap: 16px;

    .empty_image {
      width: 150px;
      height: 150px;
    }

    .connect_btn {
      color: #ff3e9a;
    }
  }
}
