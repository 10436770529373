.vibe-match-card {
  width: 250px;

  position: relative;

  .vibe-card-front {
    width: 100%;
    height: 80px;
    border-radius: 68px;
    padding-right: 4px;
    z-index: 2;
    position: relative;

    background-color: yellow;

    .image {
      width: 64px;
      height: 64px;
      border-radius: 32px;
      margin-left: 2px;
      object-fit: cover;
      background-color: rgba($color: #000000, $alpha: 0.2);

      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
    }

    .info {
      display: flex;
      flex: 1;
      flex-direction: column;
      gap: 4px;

      margin-left: 8px;
      margin-right: 8px;

      .name_container {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 4px;

        .dot {
          min-width: 6px;
          height: 6px;
          border-radius: 3px;
          background-color: black;
        }

        .name {
          text-overflow: ellipsis;
          overflow: hidden;
          // Addition lines for 2 line or multiline ellipsis
          display: -webkit-box !important;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
          white-space: normal;
        }
      }

      .description {
        text-overflow: ellipsis;
        overflow: hidden;
        // Addition lines for 2 line or multiline ellipsis
        display: -webkit-box !important;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        white-space: normal;
      }
    }
  }

  .vibe-card-back {
    position: absolute;
    top: 12px;
    left: 0px;
    width: 100%;
    height: 80px;
    border-radius: 68px;
    z-index: 1;

    background-color: rgba($color: yellow, $alpha: 0.3);
  }
}
