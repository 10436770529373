.interest_detail {
  display: flex;
  flex-direction: column;
  flex: 1;

  .detail_header {
    gap: 32px;
    padding: 32px;
    border-bottom: 1px #f3f3f3 solid;
  }

  .detail_body {
    flex: 1;
    padding: 32px;

    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 64px;

    .left_panel {
      width: 500px;

      .left_panel_content {
        display: flex;
        flex-direction: column;
        gap: 16px;
      }
    }

    .right_panel {
      width: 500px;
      display: flex;
      flex-direction: column;

      .right_panel_content {
        display: flex;
        flex-direction: column;
        gap: 32px;
      }
    }
  }
}
