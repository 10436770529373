@import "../../../shared/utils/colors.scss";

.menu-container {
  width: 310px;
  height: 100%;
  background-color: white;
  display: flex;
  flex-direction: column;
  padding-top: 32px;
  padding-left: 40px;
  border-right: $color-border 1px solid;

  gap: 8px;
}

.sticky-menu {
  position: fixed;
  top: 100px;
  left: 0;
  z-index: 998;
}

.button-wrapper {
  width: 250px;
  height: 64px;
  padding: 4px;
  border-radius: 30px;
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;

  &.active {
    background-color: $color-active;
  }

  &.main-active {
    background-color: rgba(243, 45, 152, 0.8);
  }
}

.menu-title {
  margin-left: 16px;
  font-size: 16px;

  &.pink {
    color: $color-pink;
  }
}

.menu-footer {
  position: fixed;
  bottom: 60px;
  left: 40px;
  z-index: 999;

  .check_info {
    width: 250px;
    background: linear-gradient(
      180deg,
      rgba(255, 229, 242, 0.4) 20%,
      rgba(255, 229, 242, 1) 100%
    );
    padding: 16px;
    border-radius: 16px;
    margin-bottom: 16px;

    display: flex;
    flex-direction: column;
    align-items: start;

    .check_info_title {
      font-size: 16px;
    }

    .check_info_list {
      font-size: 16px;
      margin-top: 16px;
    }
  }
}
