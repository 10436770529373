.setting-container {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .setting-body {
    width: 570px;
    height: calc(100% - 30px);
    border: 1px #f3f3f3 solid;
    padding: 30px 60px;
    margin-top: 32px;
    margin-bottom: 32px;
    overflow-y: auto;

    display: flex;
    flex-direction: column;
    gap: 24px;

    .picture-container {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 16px;

      .user_picture {
        width: 60px;
        height: 60px;
        border-radius: 60px;
        object-fit: cover;
      }

      .btn_upload {
        padding: 9px 16px;
        background-color: #f3f3f3;
        border-radius: 32px;
      }
    }

    .user-info-container {
      display: flex;
      flex-direction: column;
      gap: 24px;

      .item-row {
        height: 60px;
        background-color: #f3f3f3;
        padding: 16px;

        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
      }

      .sub_input {
        padding: 4px;
      }

      .edit_done_btn {
        margin-left: 16px;
        color: #f32d68;
      }
    }
  }

  .next-btn {
    width: 115px;
    height: 42px;
    background-color: #ea21a2;
    border-radius: 3px;
    margin-top: 24px;

    display: flex;
    align-items: center;
    justify-content: center;
  }

  .back-btn {
    width: 100%;
    height: 43px;
    border: 1px solid #ea21a2;
    border-radius: 8px;

    display: flex;
    align-items: center;
    justify-content: center;

    .btn-txt {
      color: #ea21a2;
      line-height: 12px;
      letter-spacing: 2%;
    }
  }
}
