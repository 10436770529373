.explore_body {
  display: flex;
  flex-direction: row;
  flex: 1;

  .center-content {
    width: calc(100vw - 700px);
    min-width: 400px;
    display: flex;
    flex-direction: column;
  }

  .right-content {
    width: 320px;
    border-left: 1px #f3f3f3 solid;
    margin-left: 16px;

    display: flex;
    flex-direction: column;
  }
}

.scrollUpBtn {
  position: absolute;
  bottom: 70px;
  right: 20px;
  width: 65px;
  height: 65px;
  border-radius: 40px;
  background-color: #fff;
  border: 1px solid #000;
  display: flex;
  justify-content: center;
  align-items: center;
}
