.modal_referral_code {
  width: 100%;
  height: 100%;

  .modal_content {
    .modal_body {
      width: 600px;
      background-color: white;
      border-radius: 30px;

      position: fixed;
      right: 32px;
      top: 18px;

      .carosuel_body {
        flex: 1;
      }

      .modal_header {
        width: 100%;
        height: 96px;

        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
      }

      .modal_progrees_bar {
        padding: 0px;
        margin-top: -8px;

        position: relative;

        .progrees_bar_bg {
          width: 100%;
          height: 3px;
          object-fit: cover;
        }
      }

      .container1 {
        padding: 40px;

        .right_view {
          margin-left: 24px;

          .number-view {
            width: 50px;
            padding: 8px;
            background-color: #dbf5ed;
            border-radius: 24px;
          }
        }

        .item_btn {
          width: 92px;
          height: 38px;
          border: 1px solid #f3f3f3;
          border-radius: 32px;

          display: flex;
          align-items: center;
          justify-content: center;

          .item_btn_txt {
            color: #ea21a2;
          }
        }
      }
    }
  }

  .tooltipe-layout {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
}
