.card_interested_people {
  display: flex;
  flex-direction: column;
  width: 500px;
  position: relative;

  .item_content {
    position: absolute;
    width: 100%;
    height: 100%;
    padding: 8px 16px;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    .left_content {
      display: flex;
      flex-direction: row;
      align-items: center;

      .user_info {
        display: flex;
        flex-direction: column;
        gap: 8px;
        margin-left: 16px;
      }
    }
  }
}
