.dashboard_container {
  width: 100vw;
  height: fit-content;
  display: flex;
  flex-direction: column;

  .first_container {
    display: flex;
    flex-direction: column;
    background-color: #000;
    align-items: center;
    padding: 0px 100px 100px;

    .left {
      display: flex;
      flex-direction: column;
      align-items:start;
      width: 40%;
    }
  }

  .second_container {
    display: flex;
    flex-direction: column;
    background-color: #FFC120;
    align-items: center;
    height: fit-content;

    .content {
      padding: 40px 100px 50px;
    }
  }

  .third_container {
    display: flex;
    flex-direction: column;
    background-color: #F7F7F8;
    align-items: center;
    height: fit-content;
    .content {
      padding: 40px 100px 100px 0px;
      background-image: url(../../../assets/image/db_poster3_bg.png);
      background-size: contain;
    }
  }

  .fourth_container {
    display: flex;
    flex-direction: column;
    background-color: #F7F7F8;
    align-items: center;
    height: fit-content;
    .content {
      padding: 0px 100px 0px 0px;
      background-image: url(../../../assets/image/db_poster3_bg.png);
      background-size: contain;
    }
  }

  .fifth_container {
    display: flex;
    flex-direction: column;
    background-color: #F7F7F8;
    align-items: center;
    height: fit-content;
    .content {
      padding: 40px 100px 80px 0px;
      background-image: url(../../../assets/image/db_poster3_bg.png);
      background-size: contain;
    }
  }

  .sixth_container {
    display: flex;
    flex-direction: column;
    background-color: #88DBFF;
    align-items: center;
    height: fit-content;
    .content {
      padding: 40px 100px 60px;
      background-image: url(../../../assets/image/db_poster6_bg.png);
      background-size: cover;
    }
  }

  .seventh_container {
    display: flex;
    flex-direction: column;
    background-color: #353535;
    align-items: center;
    height: fit-content;
    .content {
      padding: 60px 100px 60px 0px;

    }
  }

  .logo {
    width: 87px;
    height: 87px;
    object-fit: contain;
    // margin-top: 30px;
    // margin-bottom: -60px;
  }
  .login_button {
    border: 1px solid #FFFFFF66;
    border-radius: 50px;
    padding: 4px 4px;
    display: flex;
    align-items: center;
    height: 40px;
    // margin-bottom: 20px;
    // margin-vertical: auto;
    cursor: pointer;
  }
}

.flex_center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.justify_center {
  display: flex;
  justify-content: center;
}

.text_center {
  text-align: center;
}

.marginPadding10 {
  margin-bottom: 0px;
  margin-top: 10px;
  padding-bottom: 10px;
}