.custom_switch_button {
}

.custom_switch_button.react-toggle--checked .react-toggle-track {
  background-color: #f32d68;
}

.custom_switch_button.react-toggle--checked .react-toggle-thumb {
  border-color: transparent !important;
}

.custom_switch_button.react-toggle .react-toggle-thumb {
  border-color: transparent !important;
}
