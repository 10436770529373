@import "shared/utils/colors.scss";

.header-container {
  width: 100%;
  background-color: white;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding-top: 16px;
  padding-bottom: 16px;
  border-bottom: $color-border 1px solid;

  .header-left {
    width: 300px;
    padding-left: 40px;
    justify-content: flex-start;
  }

  .header-center {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .header-right {
    display: flex;
    flex-direction: row;
    width: 300px;

    align-items: center;
    justify-content: flex-end;
    padding-right: 40px;
  }
}

.sticky-header {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  animation: 500ms ease-in-out 0s normal none 1 running fadeInDown;
}
