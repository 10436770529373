.modal_send_invitation {
  width: 100%;
  height: 100%;

  display: flex;
  align-items: center;
  justify-content: center;

  .modal_body {
    width: 400px;
    border-radius: 32px;
    background-color: white;

    display: flex;
    flex-direction: column;
    padding: 40px 0px;

    .close-btn {
      width: 40px;
      height: 40px;
      border: 1px solid #f3f7fa;
      border-radius: 40px;
      margin-right: 20px;

      display: flex;
      align-items: center;
      justify-content: center;
    }

    .content-body {
      margin-top: 32px;

      .input-container {
        height: 60px;
        border-radius: 30px;
        border: 1px solid #ea21a2;

        padding-left: 24px;
        padding-right: 6px;
        gap: 8px;
        margin-top: 30px;
        margin-left: 40px;
        margin-right: 40px;

        display: flex;
        flex-direction: row;

        .input-box {
          flex: 1;
          height: 40px;
          border: none;
        }

        .send-btn {
          width: 100px;
          height: 48px;
          background-color: rgba($color: #ea21a2, $alpha: 0.1);
          border-radius: 48px;

          display: flex;
          align-items: center;
          justify-content: center;

          .btn-txt {
            color: #ea21a2;
          }
        }

        .copy-btn {
          width: 100px;
          height: 48px;
          background-color: rgba($color: #ea21a2, $alpha: 1);
          border-radius: 48px;

          display: flex;
          align-items: center;
          justify-content: center;
          gap: 4px;

          .btn-txt {
            color: white;
          }
        }
      }

      .divider {
        width: 100%;
        height: 1px;
        background-color: #f3f3f3;

        margin-top: 32px;
        margin-bottom: 32px;
      }

      .social_share {
        display: flex;
        flex-direction: row;

        align-items: center;
        justify-content: center;
        gap: 16px;

        .social_item {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          gap: 8px;
        }

        .copy_item {
          width: 48px;
          height: 48px;
          border-radius: 16px;
          background-color: #ff3e9a;

          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
        }
        .whatsapp_item {
          width: 48px;
          height: 48px;
          border-radius: 16px;
          background-color: #08b883;

          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
        }
        .telegram_item {
          width: 48px;
          height: 48px;
          border-radius: 16px;
          background-color: #80bbf2;

          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
        }
      }
    }
  }
}
