.login-email {
  width: 345px;

  display: flex;
  flex-direction: column;
  gap: 16px;

  .input-box {
    width: 100%;
    height: 56px;
    padding: 12px 16px;
    border: 1px solid rgba($color: #000000, $alpha: 0.3);
    border-radius: 3px;
  }

  .actions {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    .login-btn {
      width: 145px;
      height: 42px;
      background-color: #ea21a2;
      border-radius: 3px;

      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .create-btn {
    width: 100%;
    height: 43px;
    border: 1px solid #ea21a2;
    border-radius: 8px;

    display: flex;
    align-items: center;
    justify-content: center;

    .btn-txt {
      color: #ea21a2;
      line-height: 12px;
      letter-spacing: 2%;
    }
  }

  .error-message {
    margin: 0px 8px;
    color: red;
    text-align: center;
  }

  .divider {
    width: 100%;
    height: 1px;
    background-color: #c7c9ca;
    margin-top: 32px;
  }
}
