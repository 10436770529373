.interest_sub_interest {
  display: flex;
  flex-direction: column;
  gap: 16px;

  .empty_container {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 16px;

    .empty_image {
      width: 150px;
      height: 150px;
    }

    .connect_btn {
      color: #ff3e9a;
    }
  }
}
