.card_trend {
  margin-right: 16px;
  display: flex;
  position: relative;

  .bg_img {
    width: 379px;
    height: 189px;
    object-fit: contain;
  }

  .action_view {
    position: absolute;
    right: 16px;
    top: 16px;

    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-end;
    gap: 8px;
  }

  .card_trend_content {
    position: absolute;
    width: 100%;
    height: 100%;
    padding: 8px;
    display: flex;

    .image_view {
      width: 100%;
      height: 100%;
      border-radius: 40px;
      object-fit: cover;
    }

    .card_trend_body {
      position: absolute;
      width: 100%;
      height: 100%;
      padding: 16px;

      display: flex;
      flex-direction: column;

      .trend_title {
        max-width: 340px;
        text-overflow: ellipsis;
        overflow: hidden;
        // Addition lines for 2 line or multiline ellipsis
        display: -webkit-box !important;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        white-space: normal;

        color: white;
        text-shadow: -1px 0 black, 0 1px black, 1px 0 black, 0 -1px black;

        background-color: rgba(255, 255, 255, 0.5);
        padding: 0px 16px;
        border-radius: 32px;
        align-self: flex-start;
      }
    }
  }
}
