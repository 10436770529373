.main-container {
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .main_body {
    position: fixed;
    left: 330px;
    top: 100px;
    right: 0px;
    bottom: 0px;

    display: flex;
  }
}
