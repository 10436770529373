@import "../../../shared/utils/colors.scss";

.button-container {
    width: 56px;
    height: 56px;
    border: 0.1px solid $color-bg-grey;
    background-color: $color-white;
    cursor: pointer;

    &.circle-rounded {
        border-radius: 100%;
    }

    &.small {
        width: 24px;
        height: 24px;
    }

    &.medium {
        width: 48px;
        height: 48px;
    }

    &.primary {
        background-color: $color-main;
        border: none;
    }

    &.yellow {
        background-color: $color-yellow;
        border: none;
    }

    &.border-pink {
        border-color: $color-pink !important;
    }
}