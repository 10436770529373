@import "../../../shared/utils/colors.scss";

.avatar-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;

  .avatar-image {
    width: 56px;
    height: 56px;
    border: none;
    border-radius: 100%;
    object-fit: cover;
  }

  .avatar_info {
    margin-right: 16px;
  }
}
