@import "../../../../../../shared/utils/colors.scss";

.tag_view {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;

  .tag_item {
    height: 40px;
    padding: 16px;
    border-radius: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    white-space: pre;
  }

  .first_tag {
    padding-left: 24px;
    border-bottom-left-radius: 80px;
    font-size: 14px;
    color: $color-black;
    border: none;
    background-color: $color-bg-input;
    background-origin: border-box;
    background-clip: padding-box, border-box;
    box-shadow: inset 1px -3px 3px 1px rgba(101, 208, 255, 0.2),
      inset -1px 0.3px 0.5px 0.5px rgba(0, 255, 102, 0.2);
  }

  .normal_tag {
    padding-left: 16px;
    padding-right: 16px;
    font-size: 14px;
    color: $color-black;

    border-radius: 24px;
    border: none;
    background-color: $color-bg-input;
    background-origin: border-box;
    background-clip: padding-box, border-box;
    box-shadow: inset 1px -3px 3px 1px rgba(101, 208, 255, 0.2),
      inset -1px 0.3px 0.5px 0.5px rgba(0, 255, 102, 0.2);
    border-collapse: collapse;
  }

  .plus_Tag {
    padding-left: 16px;
    padding-right: 16px;
    border-bottom-right-radius: 80px;
    font-size: 14px;
    color: $color-black;
    border: none;
    background-color: $color-bg-input;
    background-origin: border-box;
    background-clip: padding-box, border-box;
    box-shadow: inset 1px -3px 3px 1px rgba(101, 208, 255, 0.2),
      inset -1px 0.3px 0.5px 0.5px rgba(0, 255, 102, 0.2);
  }

  .other_tags {
    display: flex;
    flex: 1;
    flex-direction: row;
    align-items: center;
    gap: 8px;
  }
}
