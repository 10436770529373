.no-extension {
  width: 100vw;
  height: 100vh;

  flex: 1;
  display: flex;

  .left-panel {
    flex: 1;
    display: flex;
    flex-direction: column;
    padding: 50px 60px;

    .main-body {
      flex: 1;

      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      .main-content {
        width: 460px;

        display: flex;
        flex-direction: column;
        gap: 22px;
        text-align: center;

        .register-btn {
          width: 100%;
          height: 42px;
          background-color: #ea21a2;
          border-radius: 3px;

          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }
  }

  .right-panel {
    background-color: #e3f4ff;
  }
}
