.sub_interest {
  display: flex;
  flex-direction: column;
  flex: 1;

  .header {
    gap: 32px;
    padding: 32px;
    border-bottom: 1px #f3f3f3 solid;
  }

  .subinterest_body {
    flex: 1;
    padding: 32px;
  }
}
