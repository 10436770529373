.reward_code {
  width: 100vw;
  height: 100vh;

  flex: 1;
  display: flex;

  .left-panel {
    flex: 1;
    display: flex;
    flex-direction: column;
    padding: 50px 60px;

    .main-body {
      flex: 1;

      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      .main-content {
        width: 570px;
        border: 1px solid #f3f3f3;
        padding: 50px;

        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 22px;
      }
    }
  }

  .right-panel {
    background-color: #e3f4ff;
  }
}
