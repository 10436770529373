.phone_login_password {
  width: 100vw;
  height: 100vh;

  flex: 1;
  display: flex;

  .left-panel {
    flex: 1;
    display: flex;
    flex-direction: column;
    padding: 50px 60px;

    .main-body {
      flex: 1;

      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      .main-content {
        width: 620px;
        border: 1px solid #f3f3f3;
        border-radius: 8px;
        padding: 100px;

        display: flex;
        flex-direction: column;
        gap: 22px;
        scale: 0.9;

        .error-message {
          margin: 0px 8px;
          color: red;
          text-align: center;
        }

        .input-box {
          width: 100%;
          height: 56px;
          padding: 12px 16px;
          border: 1px solid rgba($color: #000000, $alpha: 0.3);
          border-radius: 3px;
        }

        .phone_body {
          display: flex;
          flex-direction: column;
          align-items: center;
          gap: 16px;

          .phone_input_container {
            width: 100%;
            gap: 8px;

            .PhoneInputCountry {
              border: 1px solid #d9d9d9;
              padding: 16px;
              border-radius: 8px;
            }

            .PhoneInputInput {
              border: 1px solid #ea21a2;
              border-radius: 8px;
              padding: 16px;
            }
          }
        }

        .next-btn {
          width: 100%;
          height: 60px;
          background-color: #ea21a2;
          border-radius: 12px;

          display: flex;
          align-items: center;
          justify-content: center;

          &.disabled {
            background-color: #cccccc;
            pointer-events: none;
            opacity: 0.7;
          }
        }
      }
    }

    .register-btn {
      width: 100%;
      height: 42px;
      background-color: #ea21a2;
      border-radius: 3px;

      display: flex;
      align-items: center;
      justify-content: center;
    }

    .back-btn {
      width: 100%;
      height: 43px;
      border: 1px solid #ea21a2;
      border-radius: 8px;

      display: flex;
      align-items: center;
      justify-content: center;

      .btn-txt {
        color: #ea21a2;
        line-height: 12px;
        letter-spacing: 2%;
      }
    }
  }

  .right-panel {
    background-color: #FFEBF5;
    // background: linear-gradient(to left, #e6aed2, #e6aed2 50%, #fff 75%, #fff 75%);
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    // padding-left: 100px;

    .app_image {
      height: 70%;
      margin-top: 40px;
      margin-left: -20px;
      max-width: 220px;
      object-fit: contain;
      transform: rotate(8.97deg);
    }

    .border-box {
      text-align: center;
      position: absolute;
      bottom: 50px;
      max-width: 393px;
      max-height: 254px;
      // border: 2px solid #EA21A2;
      border-radius: 20px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      padding-top: 6px;
      padding-bottom: 6px;
      padding-left: 50px; 
      padding-right: 50px; 
      gap: 8px;
      background-color: white;
      // opacity: 0.85;
    }
  }

  .error-message {
    margin: 0px 8px;
    color: red;
    text-align: center;
  }

  .term_txt {
    opacity: 0.6;
  }
}
