.modal_search {
  width: 100%;
  height: 100%;

  display: flex;
  align-items: center;
  justify-content: center;

  .modal_body {
    width: 300px;
    height: calc(100vh - 116px);
    margin-top: 16px;

    display: flex;
    flex-direction: column;

    .vibe-list-body {
      flex: 1;
      overflow-y: auto;

      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }
}
