.setting_topic {
  display: flex;
  flex-direction: column;
  gap: 20px;

  .topic_title {
    color: #747474;
  }

  .val_threshold {
    color: #747474;
    text-wrap: nowrap;
  }

  .item-row {
    background-color: #f3f3f3;
    padding: 16px;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 24px;
  }
}
