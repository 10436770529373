.vibe-match-interest {
  flex: 1;
  width: 100%;
  margin-top: 16px;

  display: flex;
  flex-direction: column;

  .empty_container {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 16px;

    .empty_image {
      width: 150px;
      height: 150px;
    }
  }
}
