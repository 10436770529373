.explore_card_topic {
  width: 100%;
  min-width: 400px;
  max-width: 600px;
  position: relative;
  flex: 1;
  margin-bottom: 24px;

  .explore_card_topic_content {
    position: absolute;
    top: 32px;
    left: 32px;
    right: 32px;
    bottom: 32px;

    display: flex;
    flex-direction: column;

    .topic_name {
      text-overflow: ellipsis;
      overflow: hidden;
      // Addition lines for 2 line or multiline ellipsis
      display: -webkit-box !important;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      white-space: normal;
      text-shadow: #fff 3px 0 6px;
    }

    .bottom_container {
      display: flex;
      flex-direction: column;
      align-items: center;

      .btn_container {
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 16px;
        margin-top: 32px;

        .btn_item {
          display: flex;
          flex: 1;
        }
      }
    }
  }
}
