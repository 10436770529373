.interest_interested_people {
  display: flex;
  flex-direction: column;
  gap: 16px;

  .people_list {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }
}
