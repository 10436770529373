.modal_reward_history {
  width: 100%;
  height: 100%;

  display: flex;
  align-items: center;
  justify-content: center;

  .modal_body {
    width: 440px;
    height: 600px;
    border-radius: 32px;
    background-color: white;

    display: flex;
    flex-direction: column;
    padding: 40px 42px;

    .history_list {
      flex: 1;
      margin-top: 16px;

      display: flex;
      flex-direction: column;

      .history_item {
        width: 100%;
        height: 80px;
        border-bottom: 1px solid #f3f3f3;

        display: flex;
        flex-direction: row;
        align-items: center;

        .btn_try_again {
          height: 38px;
          border-radius: 38px;
          border: 1px solid #f3f3f3;
          padding-left: 8px;
          padding-right: 8px;

          display: flex;
          align-items: center;
          justify-content: center;

          .txt_btn {
            color: #ea21a2;
          }
        }

        .btn_accepted_btn {
          height: 38px;
          border-radius: 38px;
          border: 1px solid #f3f3f3;
          padding-left: 8px;
          padding-right: 8px;

          display: flex;
          align-items: center;
          justify-content: center;

          background-color: rgba($color: #0ead31, $alpha: 0.1);

          .txt_btn {
            color: #88d368;
          }
        }

        .btn_sent_btn {
          height: 38px;
          border-radius: 38px;
          border: 1px solid #f3f3f3;
          padding-left: 8px;
          padding-right: 8px;

          display: flex;
          align-items: center;
          justify-content: center;

          background-color: rgba($color: #0ead31, $alpha: 0.1);

          .txt_btn {
            color: #88d368;
          }
        }
      }
    }
  }

  .btn_send_invitation {
    width: 102px;
    height: 38px;
    border-radius: 38px;
    border: 1px solid #f3f3f3;
    background-color: rgba(#ea21a2, 0.1);

    display: flex;
    align-items: center;
    justify-content: center;

    .txt_btn {
      color: #ea21a2;
    }
  }
}
