.card_topic_container {
  display: flex;
  flex-direction: column;
  align-items: center;

  .explore_card_topic {
    width: 100%;
    min-width: 400px;
    max-width: 700px;
    position: relative;
    flex: 1;
    margin-bottom: 24px;

    .explore_card_topic_content {
      position: absolute;
      top: 36px;
      left: 48px;
      right: 48px;
      bottom: 36px;

      display: flex;
      flex-direction: column;

      .topic_name {
        text-overflow: ellipsis;
        overflow: hidden;
        // Addition lines for 2 line or multiline ellipsis
        display: -webkit-box !important;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        white-space: normal;
        -webkit-text-stroke-width: 1px;
        -webkit-text-stroke-color: white;

        background-color: rgba(255, 255, 255, 0.5);
        padding: 0px 16px;
        border-radius: 32px;
      }

      .center_view {
        flex: 1;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        .add_image_btn {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: center;
          padding: 0px 16px;

          width: 150px;
          height: 56px;
          border-radius: 56px;
          background-color: rgba($color: #ffffff, $alpha: 0.5);
        }
      }
    }
  }
}
