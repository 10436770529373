@import "../../../shared/utils/colors.scss";

.search-container {
  width: 300px;
  position: relative;

  .search-icon {
    position: absolute;
    top: 24px;
    left: 30px;
  }

  .search-input {
    width: 100%;
    padding: 23px 20px 23px 60px;
    border-radius: 100px;
    font-size: 14px;
    color: $color-black;
    border: none;
    background-color: $color-bg-input;
    background-origin: border-box;
    background-clip: padding-box, border-box;
    box-shadow: inset 1px -3px 3px 1px rgba(101, 208, 255, 0.2),
      inset -1px 0.3px 0.5px 0.5px rgba(0, 255, 102, 0.2);
  }

  .search-input:focus {
    outline: none !important;
  }
}
