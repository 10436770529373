@import "../../../../shared/utils/colors.scss";

.explore-vibe {
  flex: 1;

  display: flex;
  flex-direction: column;

  .item_btn {
    width: 92px;
    height: 38px;
    border: 1px solid #f3f3f3;
    border-radius: 32px;

    display: flex;
    align-items: center;
    justify-content: center;

    .item_btn_txt {
      color: #ea21a2;
    }
  }

  .search-vibe-container {
    width: calc(100% - 16px);
    position: relative;
    margin-top: 16px;

    .search-icon {
      position: absolute;
      top: 24px;
      left: 30px;
    }

    .search-input {
      width: 100%;
      padding: 23px 20px 23px 60px;
      border-radius: 100px;
      font-size: 14px;
      color: $color-black;
      border: none;
      background-color: $color-bg-input;
      background-origin: border-box;
      background-clip: padding-box, border-box;
      box-shadow: inset 1px -3px 3px 1px rgba(101, 208, 255, 0.2),
        inset -1px 0.3px 0.5px 0.5px rgba(0, 255, 102, 0.2);
    }

    .search-input:focus {
      outline: none !important;
    }
  }

  .vibe-list {
    height: calc(100vh - 230px);
    padding-left: 22px;
    padding-top: 32px;
    padding-bottom: 32px;

    display: flex;
    flex-direction: column;

    .vibe-list-body {
      height: 100%;
      overflow-y: hidden;
      margin-top: 16px;

      display: flex;
      flex-direction: column;
    }
  }

  .upgrade-level {
    height: 130px;
    margin-left: 16px;
    border-top: 1px #f3f3f3 solid;

    .btn-upgrade-level {
      width: 270px;
      height: 68px;
      border-radius: 34px;
      background-color: #e3f4ff;
      align-items: center;
    }
  }
}
