.qr_container {
  width: calc(100% - 32px);
  margin-top: 32px;
  margin-bottom: 32px;
  background: linear-gradient(
    180deg,
    rgba(255, 229, 242, 0.4) 20%,
    rgba(255, 229, 242, 1) 100%
  );
  border-radius: 32px;

  display: flex;
  flex-direction: row;
  align-items: center;
  overflow: hidden;

  .left_panel {
    flex: 1;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .setting_content {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    .qr_code_image {
      width: 230px;
      height: 230px;
      border-radius: 32px;
      object-fit: cover;
      background-color: transparent;
      border-radius: 32px;
      margin-top: 48px;

      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }

    .connect_btn {
      width: 100%;
      height: 42px;
      background-color: #ea21a2;
      border-radius: 42px;
      margin-top: 32px;

      display: flex;
      align-items: center;
      justify-content: center;
    }

    .back_btn {
      width: 100%;
      height: 42px;
      border: 1px solid #ea21a2;
      border-radius: 42px;
      background-color: white;
      margin-top: 32px;

      display: flex;
      align-items: center;
      justify-content: center;

      .btn-txt {
        color: #ea21a2;
        line-height: 12px;
        letter-spacing: 2%;
      }
    }
  }

  .right_panel {
    height: 100%;
    margin-right: 48px;

    .app_image {
      height: 100%;
      max-width: 420px;
      object-fit: cover;
    }
  }
}
