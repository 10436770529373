.modal_reward {
  width: 100%;
  height: 100%;

  .modal_content {
    .modal_body {
      width: 600px;
      background-color: white;
      border-radius: 30px;

      position: fixed;
      right: 32px;
      top: 18px;

      .carosuel_body {
        flex: 1;
      }

      .modal_header {
        width: 100%;
        height: 96px;

        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
      }

      .modal_progrees_bar {
        padding: 0px;
        margin-top: -8px;

        position: relative;

        .progrees_bar_bg {
          width: 100%;
          height: 3px;
          object-fit: cover;
        }
      }

      .container1 {
        padding: 40px;

        .right_view {
          margin-left: 24px;

          .number-view {
            width: 50px;
            padding: 8px;
            background-color: #dbf5ed;
            border-radius: 24px;
          }
        }

        .item_btn {
          width: 92px;
          height: 38px;
          border: 1px solid #f3f3f3;
          border-radius: 32px;

          display: flex;
          align-items: center;
          justify-content: center;

          .item_btn_txt {
            color: #ea21a2;
          }
        }

        .reward_list {
          width: 100%;
          min-height: 190px;
          max-height: 500px;
          padding-top: 16px;
          padding-bottom: 16px;
          border-radius: 30px;
          border: 1px solid #e3f9f3;
          margin-top: 32px;
          background: linear-gradient(to bottom, white, #e3f9f3 50%, white);

          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: center;
          gap: 32px;
          flex-wrap: wrap;
          overflow-y: auto;

          .reward_item_blur {
            filter: blur(2px);
          }

          .reward_item {
            flex-direction: column;

            .reward_name {
              height: 48px;
              max-width: 100px;
              text-overflow: ellipsis;
              overflow: hidden;
              // Addition lines for 2 line or multiline ellipsis
              display: -webkit-box !important;
              -webkit-line-clamp: 2;
              -webkit-box-orient: vertical;
              white-space: normal;
            }

            .lvl_view {
              background-color: white;
              padding: 4px 16px;
              border-radius: 40px;
              box-shadow: 0 4px 2px -2px gray;
            }

            .icon_container {
              display: flex;
              align-items: center;
              justify-content: center;

              position: relative;
              margin-top: 8px;

              .emoji_container {
                position: absolute;
                width: 100%;
                height: 100%;

                display: flex;
                align-items: center;
                justify-content: center;
              }
            }

            .tick_icon {
              position: absolute;
              top: 0;
              right: -8px;

              width: 32px;
              height: 32px;
              border-radius: 32px;
              background-color: #5bb65b;

              display: flex;
              flex-direction: row;
              align-items: center;
              justify-content: center;
            }

            .lock_icon {
              position: absolute;
              top: 0;
              right: -8px;

              width: 32px;
              height: 32px;
              border-radius: 32px;
              background-color: white;
              box-shadow: 0 4px 2px -2px gray;

              display: flex;
              flex-direction: row;
              align-items: center;
              justify-content: center;
            }
          }
        }
      }

      .next-btn {
        position: absolute;
        top: 50%;
        right: -26px;
        transform: translateY(-50%);
        background-color: white;

        width: 52px;
        height: 52px;
        border-radius: 52px;
        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);

        display: flex;
        align-items: center;
        justify-content: center;
      }

      .prev-btn {
        position: absolute;
        top: 50%;
        left: -26px;
        transform: translateY(-50%) rotate(180deg);
        background-color: white;

        width: 52px;
        height: 52px;
        border-radius: 52px;
        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);

        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }

  .tooltipe-layout {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
}
