.vibe-card {
  width: calc(100% - 16px);
  margin-bottom: 24px;

  position: relative;

  .vibe-card-front {
    width: 100%;
    height: 68px;
    border-radius: 68px;
    padding-right: 4px;
    z-index: 2;
    position: relative;

    background-color: #e3f4ff;

    .image {
      width: 64px;
      height: 64px;
      border-radius: 32px;
      margin-left: 2px;
      object-fit: cover;
    }

    .info {
      display: flex;
      flex: 1;
      flex-direction: column;
      gap: 4px;

      margin-left: 8px;
      margin-right: 8px;

      .name_container {
        width: 100px;
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 4px;

        .dot {
          min-width: 6px;
          height: 6px;
          border-radius: 3px;
          background-color: black;
        }

        .name {
          text-overflow: ellipsis;
          overflow: hidden;
          display: -webkit-box !important;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          white-space: normal;
        }
      }
    }
  }

  .vibe-card-back {
    position: absolute;
    top: 12px;
    left: 0px;
    width: 100%;
    height: 68px;
    border-radius: 68px;
    z-index: 1;

    background-color: rgba($color: #e3f4ff, $alpha: 0.3);
  }
}
