.verification_container {
  flex: 1;

  .content_body {
    width: 460px;
    height: 528px;
    // border: 1px solid #f3f3f3;
    padding-left: 16px;
    padding-right: 16px;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;

    .submit-btn {
      width: 100%;
      height: 42px;
      background-color: #ea21a2;
      border-radius: 3px;
      margin-top: 50px;

      display: flex;
      align-items: center;
      justify-content: center;
    }

    .error-message {
      margin-top: 32px;
      color: red;
      text-align: center;
    }

    .verification-code-input input[type="tel"] {
      font-family: inherit;
    }
  }
}
