.set_profile {
  width: 100vw;
  height: 100vh;

  flex: 1;
  display: flex;

  .left-panel {
    flex: 1;
    display: flex;
    flex-direction: column;
    padding: 50px 60px;

    .main-body {
      flex: 1;

      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      .main-content {
        width: 620px;
        border: 1px solid #f3f3f3;
        border-radius: 8px;
        padding: 80px;

        display: flex;
        flex-direction: column;
        gap: 22px;

        .set_profile_body {
          display: flex;
          flex-direction: column;
          align-items: center;
          gap: 16px;

          .picture_view {
            display: flex;
            flex-direction: column;
            align-items: center;

            .upload_picture_btn {
              padding: 12px;
              background-color: #f9f9f9;
              border-radius: 32px;
              margin-top: 16px;
            }
          }

          .user_name {
            width: 100%;
            height: 56px;
            border: 1px solid rgba($color: #000000, $alpha: 0.23);
            padding: 16px;

            .name_input {
              border: none;
              width: 100%;
              height: 100%;
            }

            .name_input:focus {
              outline: none;
            }
          }
        }

        .error-message {
          margin: 0px 8px;
          color: red;
          text-align: center;
        }

        .next-btn {
          width: 100%;
          height: 60px;
          background-color: #ea21a2;
          border-radius: 12px;

          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }
  }

  .right-panel {
    background-color: #e3f4ff;
  }

  .error-message {
    margin: 0px 8px;
    color: red;
    text-align: center;
  }
}
