.vibe_match_percent_horizontal {
  display: flex;
  flex-direction: column;
  gap: 4px;

  .vibe_match_percent_graph {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 4px;

    .vibe_match_percent_item {
      min-width: 30px;
      height: 3px;
      border-radius: 1px;
    }
  }
}
