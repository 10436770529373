.common_dropdown {
  .Dropdown-control {
    border-color: rgba($color: #000000, $alpha: 0.1);
    border-radius: 32px;
    padding: 8px 16px;
    cursor: pointer;

    display: flex;
    flex-direction: row;
    gap: 8px;
  }
}
