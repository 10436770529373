.vibe-match-container {
  flex: 1;

  display: flex;
  flex-direction: column;

  .header {
    gap: 32px;
    padding: 32px;

    border-bottom: 1px #f3f3f3 solid;
  }

  .match-body {
    flex: 1;
    padding: 32px;
    padding-bottom: 0px;
    display: flex;
    flex-direction: column;
    align-items: center;

    padding-left: 100px;
    padding-right: 100px;

    .body-header {
      width: 100%;
      justify-content: space-between;

      .match-item {
        flex: 1;
        gap: 8px;

        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;

        .user_info {
          max-width: 150px;
          display: flex;
          flex-direction: column;
          align-items: center;
          gap: 8px;
          margin-top: 20px;

          .user_name {
            text-overflow: ellipsis;
            overflow: hidden;
            // Addition lines for 2 line or multiline ellipsis
            display: -webkit-box !important;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
            white-space: normal;
          }
        }

        .match-level {
          opacity: 0.6;
        }
      }
    }
  }
}
