.modal_user_qr {
  width: 100%;
  height: 100%;

  display: flex;
  align-items: center;
  justify-content: center;

  .modal_body {
    border-radius: 32px;
    background-color: white;

    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 50px 40px;

    .qr_code_image {
      width: 400px;
      height: 400px;
      border-radius: 32px;
      object-fit: cover;
      background-color: transparent;
      border-radius: 32px;
      margin-top: 16px;

      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }

    .btn-thank {
      width: 170px;
      height: 48px;
      border-radius: 8px;
      background-color: #ea21a2;
      margin-top: 40px;

      display: flex;
      align-items: center;
      justify-content: center;

      .btn-txt {
        color: white;
      }
    }
  }
}
