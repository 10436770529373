.mobile_intro {
  width: 100vw;
  height: 100vh;

  display: flex;
  flex-direction: column;
  gap: 40px;
  align-items: center;
  justify-content: center;

  .app_info {
    display: flex;
    flex-direction: column;
    gap: 40px;
    align-items: center;

    .app_image {
      width: 120px;
      height: 120px;
      border-radius: 120px;
      border: 2px solid #ea21a1;
    }
  }
  .description {
    text-align: center;
    line-height: 30px;

    .web_link {
      color: #ea21a1;
    }
  }

  .go_to_mobile_btn {
    width: 80%;
    height: 43px;
    background-color: #ea21a2;
    border-radius: 8px;

    display: flex;
    align-items: center;
    justify-content: center;

    .btn-txt {
      color: white;
      line-height: 12px;
      letter-spacing: 2%;
    }
  }
}
