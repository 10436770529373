$color-main: #ff3e9a;
$color-main-hightlight: #f52d8d;
$color-pink: #ffe5f2;
$color-white: #ffffff;
$color-black: #000000;
$color-border: rgba(0, 0, 0, 0.1);
$color-bg-black: #494a4b;
$color-bg-grey: #555657;
$color-bg-input: #F6FBFF;
$color-active: #E3F4FE;
$color-yellow: #FBFF39;
$color-pink: #F32D68;