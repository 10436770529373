@import "~bootstrap/scss/bootstrap.scss";
@import "shared/utils/colors.scss";

@mixin fontFace($family, $src, $style: normal, $weight: 400) {
  @font-face {
    font-family: $family;
    src: url("#{$src}.otf") format("truetype");
    font-style: $style;
    font-weight: $weight;
  }
}

@include fontFace("Rubik", "./assets/fonts/Rubik/Rubik-Black", bold, 800);

@include fontFace("Adversal", "./assets/fonts/Adversal/adversal", normal, 600);

@include fontFace(
  "Outfit-Bold",
  "./assets/fonts/Outfit/Outfit-SemiBold",
  normal,
  600
);

@include fontFace(
  "Outfit-Medium",
  "./assets/fonts/Outfit/Outfit-Medium",
  normal,
  500
);

@include fontFace(
  "Outfit-Regular",
  "./assets/fonts/Outfit/Outfit-Regular",
  normal,
  400
);

.font-bold {
  font-family: "Outfit-Bold", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Outfit-Bold", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif !important;
}
.font-medium {
  font-family: "Outfit-Medium", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Outfit-Medium", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif !important;
}
.font-regular {
  font-family: "Outfit-Regular", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Outfit-Regular", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif !important;
}

.font-app-title {
  font-family: "Adversal", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Outfit-Regular", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif !important;
  margin-top: 8px;
}

.font-adversal {
  font-family: "Adversal", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Outfit-Regular", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif !important;
}

.font-rubik {
  font-family: "Rubik";
}

.root_container {
  width: 100vw;
  height: 100vh;
}

.clickable {
  cursor: pointer;

  display: flex;
  align-items: center;
  justify-content: center;
}

.font8 {
  font-size: 8px !important;
}

.font10 {
  font-size: 10px !important;
}

.font12 {
  font-size: 12px !important;
}

.font14 {
  font-size: 14px !important;
}

.font16 {
  font-size: 16px !important;
}

.font18 {
  font-size: 18px !important;
}

.font20 {
  font-size: 20px !important;
}
.font22 {
  font-size: 22px !important;
}

.font23 {
  font-size: 23px !important;
}
.font24 {
  font-size: 24px !important;
}
.font26 {
  font-size: 26px !important;
}
.font28 {
  font-size: 28px !important;
}
.font30 {
  font-size: 30px !important;
}
.font32 {
  font-size: 32px !important;
}

.font36 {
  font-size: 36px !important;
}

.font40 {
  font-size: 40px !important;
}
.font42 {
  font-size: 42px !important;
}
.font44 {
  font-size: 44px !important;
}
.font56 {
  font-size: 56px !important;
}

.font64 {
  font-size: 64px !important;
  line-height: 72px;
}

.font72 {
  font-size: 72px !important;
}

.text_no_wrap {
  white-space: nowrap;
}

.row_space_between {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}

.row_align_items {
  display: flex;
  align-items: center;
}

::-webkit-scrollbar {
  display: none;
}

.align-center {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.center {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.thumb-vertical {
  z-index: 3;
  border-radius: 15px;
  background-color: $color-main;

  width: 0px !important;
  height: 0px;
}

.thumb-horizontal {
  z-index: 3;
  border-radius: 15px;
  background-color: $color-main;

  width: 0px !important;
  height: 0px;
}

.text_no_wrap {
  white-space: nowrap;
}

.border_less_input {
  border: none;
}
.border_less_input:focus {
  outline: none;
}

.app-logo {
  width: 40px;
  height: 40px;
  object-fit: contain;
  margin-right: 8px;
}

.image_view_modal {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100vw;
  height: 100vh;

  z-index: 1000000;
}

.hyper_text {
  color: blue;
}
