@import "shared/utils/colors.scss";

.explore_trend {
  display: flex;
  flex-direction: column;
  margin-top: 32px;
  width: 100%;

  .trend_title {
    margin-bottom: 16px;
  }

  .trend_list {
    width: 100%;
    height: 190px;
    display: flex;
    flex-direction: row;

    .track-horizontal {
      background-color: blue;
      width: 100%;
      left: 0px;
      bottom: 0px;
      height: 10px !important;
    }

    .trend_list_scroll {
      width: 100%;
      display: flex;
      flex-direction: row;
    }
  }
}
