.login-container {
  width: 100vw;
  height: 100vh;

  flex: 1;
  display: flex;

  .left-panel {
    flex: 1;
    display: flex;
    flex-direction: column;
    padding: 50px 60px;

    .main-body {
      flex: 1;

      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }

    .term_txt {
      margin-top: 24px;
      opacity: 0.6;
    }
  }

  .right-panel {
    background-color: #FFEBF5;
    // background: linear-gradient(to left, #e6aed2, #e6aed2 50%, #fff 75%, #fff 75%);
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    // padding-left: 100px;

    .app_image {
      height: 70%;
      margin-top: 40px;
      margin-left: -20px;
      max-width: 220px;
      object-fit: contain;
      transform: rotate(8.97deg);
    }

    .border-box {
      text-align: center;
      position: absolute;
      bottom: 50px;
      max-width: 393px;
      max-height: 254px;
      // border: 2px solid #EA21A2;
      border-radius: 20px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      padding-top: 6px;
      padding-bottom: 6px;
      padding-left: 50px; 
      padding-right: 50px; 
      gap: 8px;
      background-color: white;
      // opacity: 0.85;
    }
  }
}
