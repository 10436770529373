.pink-round-avatar {
  display: flex;
  align-items: center;
  justify-content: center;

  border: 1px solid #f32d68;

  .user-image {
    object-fit: cover;
  }
}
