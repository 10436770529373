.reset_password_container {
  width: 100vw;
  height: 100vh;

  flex: 1;
  display: flex;

  .left-panel {
    flex: 1;
    display: flex;
    flex-direction: column;
    padding: 50px 60px;

    .main-body {
      flex: 1;

      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      .main-content {
        width: 460px;

        display: flex;
        flex-direction: column;
        gap: 22px;

        .error-message {
          margin: 0px 8px;
          color: red;
          text-align: center;
        }

        .input-box {
          width: 100%;
          height: 56px;
          padding: 12px 16px;
          border: 1px solid rgba($color: #000000, $alpha: 0.3);
          border-radius: 3px;
        }

        .next-btn {
          width: 115px;
          height: 42px;
          background-color: #ea21a2;
          border-radius: 3px;
          margin-top: 24px;

          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }

    .register-btn {
      width: 100%;
      height: 42px;
      background-color: #ea21a2;
      border-radius: 3px;

      display: flex;
      align-items: center;
      justify-content: center;
    }

    .back-btn {
      width: 100%;
      height: 43px;
      border: 1px solid #ea21a2;
      border-radius: 8px;

      display: flex;
      align-items: center;
      justify-content: center;

      .btn-txt {
        color: #ea21a2;
        line-height: 12px;
        letter-spacing: 2%;
      }
    }
  }

  .right-panel {
    background-color: #e3f4ff;
  }

  .error-message {
    margin: 0px 8px;
    color: red;
    text-align: center;
  }
}
