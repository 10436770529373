.setting-social-media {
  display: flex;
  flex-direction: column;
  gap: 20px;

  .social-title {
    color: #747474;
  }

  .social-item {
    width: 100%;
    height: 48px;
    border-radius: 48px;
    border: 1px #f3f3f3 solid;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    .social-icon {
      width: 48px;
      height: 48px;
      border-radius: 48px;
      border: 1px #f3f3f3 solid;

      display: flex;
      align-items: center;
      justify-content: center;
    }

    .social-name {
      margin-left: 16px;
    }

    .social-action-icon {
      margin-right: 16px;
    }
  }
}
