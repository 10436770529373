.reward_code_body {
  width: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 22px;

  .register-btn {
    width: 100%;
    height: 42px;
    background-color: #ea21a2;
    border-radius: 3px;

    display: flex;
    align-items: center;
    justify-content: center;
  }

  .skip-btn {
    width: 100%;
    height: 42px;
    border: 1px solid #ea21a2;
    border-radius: 3px;

    display: flex;
    align-items: center;
    justify-content: center;

    .btn-txt {
      color: #ea21a2;
      line-height: 12px;
      letter-spacing: 2%;
    }
  }

  .code-input {
    text-transform: uppercase;
  }
}
