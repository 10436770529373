.card_hidden_topic_container {
  display: flex;
  flex-direction: column;
  align-items: center;

  .explore_card_topic {
    width: 100%;
    min-width: 400px;
    max-width: 600px;
    position: relative;
    flex: 1;
    margin-bottom: 24px;

    object-fit: cover;

    .bg_image {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .card_content {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    display: flex;
    justify-content: center;
    padding-top: 64px;
  }
}
