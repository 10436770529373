.card_subinterest {
  width: 100%;
  height: 100%;

  display: flex;
  position: relative;

  .bg_img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  .card_subinterest_content {
    position: absolute;
    width: 100%;
    height: 100%;
    padding-left: 10%;
    padding-right: 10%;

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    .interest_name {
      text-overflow: ellipsis;
      overflow: hidden;
      // Addition lines for 2 line or multiline ellipsis
      display: -webkit-box !important;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      white-space: normal;
    }
  }
}
