.interest_image_view {
  display: flex;
  position: relative;
  flex-direction: column;

  .sub_action {
    position: absolute;
    width: 100%;
    height: 100%;

    display: flex;
    align-items: center;
    justify-content: center;

    .add_image_btn {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      padding: 0px 16px;

      width: 150px;
      height: 56px;
      border-radius: 56px;
      background-color: rgba($color: #ffffff, $alpha: 0.5);
    }
  }
}
